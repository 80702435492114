var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable, toJS } from 'mobx';
import CutshapePatent from './CutshapePatent';
export const SHAPE_ROUND = 2;
export const SHAPE_PRINCESS = 4;
export const SHAPE_CUSHION = 9;
export const SHAPE_OVAL = 11;
export const SHAPE_MARQUISE = 80;
export const SHAPE_PEAR = 22;
export const SHAPE_RADIANT = 83;
export const SHAPE_EMERALD = 90;
export const SHAPE_HEART = 85;
export const SHAPE_CUBE = 204;
export const SHAPE_OTHER = 113;
export const SHAPE_CUBOID = 'cuboid'; // we don't have such cut shape in system
export const MAIN_SHAPES_IDS = [
    SHAPE_ROUND,
    SHAPE_PRINCESS,
    SHAPE_CUSHION,
    SHAPE_OVAL,
    SHAPE_MARQUISE,
    SHAPE_PEAR,
    SHAPE_RADIANT,
    SHAPE_EMERALD,
    SHAPE_HEART,
    SHAPE_OTHER,
];
export class CutshapeModel {
    id;
    title;
    cutShapePatents;
    isPatented;
    parent;
}
export default class Cutshape extends CutshapeModel {
    title = '';
    parent = null;
    cutShapePatents = [new CutshapePatent()];
    isPatented = false;
    constructor(modelData) {
        super();
        Object.assign(this, modelData);
    }
    addEmptyPatent = () => {
        this.cutShapePatents.push(new CutshapePatent());
    };
    removePatentByIndex(index) {
        this.cutShapePatents.splice(index, 1);
    }
    toDto() {
        return toJS({
            ...this,
            parent: this.parent?.id,
        });
    }
}
__decorate([
    observable
], Cutshape.prototype, "title", void 0);
__decorate([
    observable
], Cutshape.prototype, "parent", void 0);
__decorate([
    observable
], Cutshape.prototype, "cutShapePatents", void 0);
__decorate([
    observable
], Cutshape.prototype, "isPatented", void 0);
__decorate([
    action
], Cutshape.prototype, "addEmptyPatent", void 0);
__decorate([
    action
], Cutshape.prototype, "removePatentByIndex", null);
