var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from 'mobx';
import { ALL_PRODUCT_TYPES } from '@/product/constants/productTypes';
import SetupPresetGroup from '../entity/SetupPresetGroup';
import { setupPresetRepository } from './SetupPresetRepository';
class SetupPresetGroupRepository {
    setupPresetGroups = [];
    spgIdsMapSortedByProductType = {};
    spgsMapSortedByProductType = {};
    initSetupPresetGroups(setupPresetGroups) {
        this.setupPresetGroups = setupPresetGroups
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .map((spg) => new SetupPresetGroup({
            ...spg,
            sp: spg.sp.map((id) => setupPresetRepository.findSetupPresetById(id)),
        }));
    }
    initSorts(sorts) {
        ALL_PRODUCT_TYPES.forEach((productType) => {
            if (sorts[productType]) {
                this.spgIdsMapSortedByProductType[productType] = sorts[productType].spgList;
            }
        });
    }
    getSortedSpgsByProductType(productType) {
        if (!Object.keys(this.spgsMapSortedByProductType).includes(productType)) {
            if (this.spgIdsMapSortedByProductType[productType]) {
                this.spgsMapSortedByProductType[productType] = this.spgIdsMapSortedByProductType[productType]
                    .map((spgId) => this.findSetupPresetGroupById(spgId))
                    .filter((spg) => Boolean(spg));
            }
        }
        return this.spgsMapSortedByProductType[productType];
    }
    findSetupPresetGroupById(id) {
        if (!this.setupPresetGroups || this.setupPresetGroups.length === 0) {
            return null;
        }
        return this.setupPresetGroups.find((spg) => spg.id === id) ?? null;
    }
    findSetupPresetGroupBySetupPresetId(id) {
        if (!this.setupPresetGroups || this.setupPresetGroups.length === 0) {
            return null;
        }
        const setupPresetGroups = [];
        this.setupPresetGroups.forEach((spg) => {
            spg.sp.forEach((sp) => {
                if (sp?.id === id) {
                    setupPresetGroups.push(spg);
                }
            });
        });
        return setupPresetGroups;
    }
}
__decorate([
    observable
], SetupPresetGroupRepository.prototype, "setupPresetGroups", void 0);
export const setupPresetGroupRepository = new SetupPresetGroupRepository();
