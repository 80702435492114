import { isArray, isNumeric } from '@cutwise/utils';
/**
 * Filters array of any type to only numeric values.
 *
 * @param {any[]} arr - Array of any type.
 * @returns number[]
 */
export function filterNumericArray(arr) {
    if (isArray(arr) && arr.length > 0) {
        return arr.filter((value) => isNumeric(value)).map((value) => Number(value));
    }
    return [];
}
