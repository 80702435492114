import { generateSearchQuery, parseSearchQuery, queryAPI, } from '@cutwise/utils';
import { addPrefixToHost } from '@/common/helpers/addPrefixToHost';
import { NETBOX_ORIGIN } from '@/netbox/constants/network';
export default class URLBuilder {
    static params() {
        return { ...queryAPI.params };
    }
    static buildUrl(path, params) {
        let url = `${window.location.origin}/${path}`;
        if (!url.includes(NETBOX_ORIGIN)) {
            url = `${addPrefixToHost('api')}/${path}`;
        }
        return `${url}${URLBuilder.buildQueryString(params)}`;
    }
    static buildQueryString(params, options = {}) {
        return generateSearchQuery(params, { encode: true, ...options });
    }
    static parseQueryString(search, options = {}) {
        return parseSearchQuery(decodeURIComponent(search), options);
    }
    static replace(path) {
        window.history.replaceState({}, '', path);
    }
    static toAbsoluteUrl(path) {
        return `${window.location.origin}${path}`;
    }
}
