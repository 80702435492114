var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from 'mobx';
import useStore from '@/common/hooks/useStore';
import Diamond from '../../product/entity/Diamond';
import ReferenceAPI from '../api/ReferenceAPI';
class ReferenceStore {
    showReference = false;
    referenceAPI;
    constructor() {
        this.referenceAPI = new ReferenceAPI();
    }
    referenceMap = {};
    fetch(product) {
        if (product.carat === 0) {
            return Promise.resolve(null);
        }
        if (this.referenceMap[product.carat]) {
            return Promise.resolve(this.referenceMap[product.carat]);
        }
        return this.referenceAPI.fetchItem(product).then((res) => {
            this.referenceMap[product.carat] = new Diamond(res.content);
            return this.referenceMap[product.carat];
        });
    }
    setShowReference = (showReference) => {
        this.showReference = showReference;
    };
    toggleShowReference = () => {
        this.setShowReference(!this.showReference);
    };
}
__decorate([
    observable
], ReferenceStore.prototype, "showReference", void 0);
__decorate([
    action
], ReferenceStore.prototype, "setShowReference", void 0);
__decorate([
    action
], ReferenceStore.prototype, "toggleShowReference", void 0);
export const useReferenceStore = () => useStore(ReferenceStore);
export const referenceStore = new ReferenceStore();
export default ReferenceStore;
