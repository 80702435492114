export default class ToJSONSerializationStrategy {
    static serialize(data) {
        if (!data) {
            return null;
        }
        return JSON.stringify(data);
    }
    static contentType() {
        return 'application/json; charset=UTF-8';
    }
}
