import { jsx as _jsx } from "react/jsx-runtime";
import { isArray } from '@cutwise/utils';
import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import Link from '@/common/components/Link/Link';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { datesDiff } from '@/common/helpers/datesDiff';
import { TaskState } from '@/tasks/constants/state';
import { generateTasksPath } from '@/tasks/helpers/generateTasksPath';
export const FIELD_PRODUCT_TASKS_AVAILABLE_AT = {
    label: 'Render in',
    fullLabel: 'Task Available At',
    key: 'productTasksAvailableAt',
    type: FieldType.Custom,
    icon: '',
    getter(p) {
        if (!isArray(p?.taskQueue)) {
            return null;
        }
        const sortedTasks = p.taskQueue.slice().sort((a, b) => (b.availableAt < a.availableAt ? -1 : 1));
        const maxAvailableTask = sortedTasks?.[0]?.availableAt;
        if (!maxAvailableTask) {
            return null;
        }
        const [label] = datesDiff(new Date(), maxAvailableTask);
        if (!label) {
            return null;
        }
        return (_jsx(Link, { to: generateTasksPath({ title: p.b2bSid, taskState: [TaskState.Pending] }, { createdAt: 'desc' }), external: true, children: label }));
    },
    getterPlain() {
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
