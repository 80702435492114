import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import DOMPurify from 'dompurify';
import styles from './RichText.module.less';
function RichText(props, ref) {
    const { value, className, ...rest } = props;
    // DOMPurify unsupported only in legacy browsers which cutwise not support anyway
    const html = useMemo(() => {
        return DOMPurify.isSupported ? DOMPurify.sanitize(value) : '';
    }, [value]);
    return (_jsx("div", { ref: ref, className: cls(styles.richText, className), dangerouslySetInnerHTML: { __html: html }, ...rest }));
}
export default observer(forwardRef(RichText));
