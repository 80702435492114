import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from 'react';
const MenuContext = createContext(null);
/**
 * MenuProvider позволяет передать один обработчик события для всех дочерних MenuItem
 * (обычно это обработчик закрытия всплывающего окна)
 *
 * @example
 * <MenuProvider onClick={() => setIsOpen(false)}>
 *   <FancyPopup
 *     isOpen={isOpened}
 *     onChangeState={setIsOpen}
 *     body={(
 *       <MenuList>
 *         <MenuItem onClick={forward}>Forward</MenuItem>
 *         <MenuItem onClick={saveAsFolder}>Save as Folder</MenuItem>
 *         <MenuItem onClick={unpinAll}>Unpin All</MenuItem>
 *       </MenuList>
 *     )}
 *   />
 * </MenuProvider>
 */
export function MenuProvider(props) {
    const { onClick, children } = props;
    return _jsx(MenuContext.Provider, { value: { onClick }, children: children });
}
export default MenuContext;
