import { FIELD_ALT_SKU } from '@/catalog/constants/fields/altSku';
import { FIELD_AVG_BRIGHTNESS } from '@/catalog/constants/fields/averageBrightness';
import { FIELD_BRILLIANCE } from '@/catalog/constants/fields/brilliance';
import { FIELD_BRILLIANCE_V2 } from '@/catalog/constants/fields/brillianceV2';
import { FIELD_CARAT_WEIGHT } from '@/catalog/constants/fields/caratWeight';
import { FIELD_CERTIFICATE } from '@/catalog/constants/fields/certificate';
import { FIELD_CHROMA } from '@/catalog/constants/fields/chroma';
import { FIELD_CLARITY } from '@/catalog/constants/fields/clarity';
import { FIELD_COLLECTION_COMMENT } from '@/catalog/constants/fields/collectionComment';
import { FIELD_COLOR_GRADE } from '@/catalog/constants/fields/colorGrade';
import { FIELD_COMBINED_COMMENT_AND_SELLER_NOTE } from '@/catalog/constants/fields/combinedCommentAndSellerNote';
import { FIELD_CREATED_AT } from '@/catalog/constants/fields/createdAt';
import { FIELD_CROWN_ANGLE } from '@/catalog/constants/fields/crownAngle';
import { FIELD_CROWN_COMPOSED } from '@/catalog/constants/fields/crownComposed';
import { FIELD_CROWN_MM } from '@/catalog/constants/fields/crownMM';
import { FIELD_CROWN_PC } from '@/catalog/constants/fields/crownPc';
import { FIELD_CULET } from '@/catalog/constants/fields/culet';
import { FIELD_CULET_COMPOSED } from '@/catalog/constants/fields/culetComposed';
import { FIELD_CULET_MM } from '@/catalog/constants/fields/culetMM';
import { FIELD_CULET_PC } from '@/catalog/constants/fields/culetPc';
import { FIELD_CUSTOM_BRILLIANCE } from '@/catalog/constants/fields/customBrilliance';
import { FIELD_CUSTOM_COLOR } from '@/catalog/constants/fields/customColor';
import { FIELD_CUSTOM_CUT_PERFORMANCE } from '@/catalog/constants/fields/customCutPerformance';
import { FIELD_CUSTOM_GIRDLE_BEAUTY } from '@/catalog/constants/fields/customGirdleBeauty';
import { FIELD_CUT } from '@/catalog/constants/fields/cut';
import { FIELD_CUT_PERFORMANCE } from '@/catalog/constants/fields/cutPerformance';
import { FIELD_DEAD_ZONE } from '@/catalog/constants/fields/deadZone';
import { FIELD_DEPTH_COMPOSED } from '@/catalog/constants/fields/depthComposed';
import { FIELD_DEPTH_MM } from '@/catalog/constants/fields/depthMM';
import { FIELD_DEPTH_PC } from '@/catalog/constants/fields/depthPC';
import { FIELD_DIAMETER_RATIO } from '@/catalog/constants/fields/diameterRatio';
import { FIELD_DIMENSIONS } from '@/catalog/constants/fields/dimensions';
import { FIELD_DRIVE_PROJECT_SUMMARY } from '@/catalog/constants/fields/driveProjectSummary';
import { FIELD_EXPENSES } from '@/catalog/constants/fields/expenses';
import { FIELD_FACE_BRIGHTNESS } from '@/catalog/constants/fields/faceBrightness';
import { FIELD_FIRE } from '@/catalog/constants/fields/fire';
import { FIELD_FIRE_MAP } from '@/catalog/constants/fields/fireMap';
import { FIELD_FLUOR } from '@/catalog/constants/fields/fluor';
import { FIELD_FLUOR_STRENGTH } from '@/catalog/constants/fields/fluorStrength';
import { FIELD_GIRDLE } from '@/catalog/constants/fields/girdle';
import { FIELD_GIRDLE_COMPOSED } from '@/catalog/constants/fields/girdleComposed';
import { FIELD_GIRDLE_MM } from '@/catalog/constants/fields/girdleMM';
import { FIELD_GIRDLE_PC } from '@/catalog/constants/fields/girdlePc';
import { FIELD_GLOBAL_ACCESS } from '@/catalog/constants/fields/globalAccess';
import { FIELD_HISTOGRAM } from '@/catalog/constants/fields/histogram';
import { FIELD_LABELS } from '@/catalog/constants/fields/labels';
import { FIELD_LABELS_EXTENDED } from '@/catalog/constants/fields/labelsExtended';
import { FIELD_LENGTH } from '@/catalog/constants/fields/length';
import { FIELD_LW } from '@/catalog/constants/fields/lw';
import { FIELD_NEXT_PAYMENT } from '@/catalog/constants/fields/nextPayment';
import { FIELD_OPTICAL_SYMMETRY } from '@/catalog/constants/fields/opticalSymmetry';
import { FIELD_PAVILION_ANGLE } from '@/catalog/constants/fields/pavilionAngle';
import { FIELD_PAVILION_COLOR } from '@/catalog/constants/fields/pavilionColor';
import { FIELD_PAVILION_COMPOSED } from '@/catalog/constants/fields/pavilionComposed';
import { FIELD_PAVILION_FIRST_ANGLE } from '@/catalog/constants/fields/pavilionFirstAngle';
import { FIELD_PAVILION_MM } from '@/catalog/constants/fields/pavilionMM';
import { FIELD_PAVILION_PC } from '@/catalog/constants/fields/pavilionPc';
import { FIELD_POLISH } from '@/catalog/constants/fields/polish';
import { FIELD_PRICE } from '@/catalog/constants/fields/price';
import { FIELD_PRICE_CT } from '@/catalog/constants/fields/priceCt';
import { FIELD_PRIVATE_ACCESS } from '@/catalog/constants/fields/privateAccess';
import { FIELD_PRODUCT_TASKS_AVAILABLE_AT } from '@/catalog/constants/fields/productTasksAvailableAt';
import { FIELD_SCINTILLATION } from '@/catalog/constants/fields/scintillation';
import { FIELD_SCINTILLATION_MAP } from '@/catalog/constants/fields/scintillationMap';
import { FIELD_SELLER } from '@/catalog/constants/fields/seller';
import { FIELD_SELLER_NOTE } from '@/catalog/constants/fields/sellerNote';
import { FIELD_SHAPE } from '@/catalog/constants/fields/shape';
import { FIELD_SHAPE_NORMALIZED_PERFORMANCE } from '@/catalog/constants/fields/shapeNormalizedPerformance';
import { FIELD_SHORT_VIEW } from '@/catalog/constants/fields/shortView';
import { FIELD_SKU } from '@/catalog/constants/fields/sku';
import { FIELD_SPREAD } from '@/catalog/constants/fields/spread';
import { FIELD_SPREAD_CT } from '@/catalog/constants/fields/spreadCt';
import { FIELD_SPREAD_PC } from '@/catalog/constants/fields/spreadPc';
import { FIELD_SYMMETRY } from '@/catalog/constants/fields/symmetry';
import { FIELD_TABLE_COLOR } from '@/catalog/constants/fields/tableColor';
import { FIELD_TABLE_COMPOSED } from '@/catalog/constants/fields/tableComposed';
import { FIELD_TABLE_MM } from '@/catalog/constants/fields/tableMM';
import { FIELD_TABLE_PC } from '@/catalog/constants/fields/tablePc';
import { FIELD_WIDTH } from '@/catalog/constants/fields/width';
export const ONLY_INTERNAL_USER_FIELDS = [
    FIELD_FIRE_MAP,
    FIELD_BRILLIANCE_V2,
    FIELD_DEAD_ZONE,
    FIELD_SCINTILLATION,
    FIELD_SCINTILLATION_MAP,
];
export const DIAMOND_BASE_COMBO_FIELDS = [
    FIELD_PRICE,
    FIELD_PRICE_CT,
    FIELD_CARAT_WEIGHT,
    FIELD_CERTIFICATE,
    FIELD_SHAPE_NORMALIZED_PERFORMANCE,
    FIELD_CUT_PERFORMANCE,
    FIELD_FIRE,
    FIELD_BRILLIANCE,
    FIELD_BRILLIANCE_V2,
    FIELD_SCINTILLATION,
    FIELD_OPTICAL_SYMMETRY,
    FIELD_SPREAD,
    FIELD_FLUOR,
    FIELD_FACE_BRIGHTNESS,
    FIELD_AVG_BRIGHTNESS,
    FIELD_DEAD_ZONE,
    FIELD_CUSTOM_GIRDLE_BEAUTY,
    FIELD_CUSTOM_CUT_PERFORMANCE,
    FIELD_CUSTOM_BRILLIANCE,
    FIELD_COLOR_GRADE,
    FIELD_CUSTOM_COLOR,
    FIELD_PAVILION_COLOR,
    FIELD_TABLE_COLOR,
    FIELD_HISTOGRAM,
    FIELD_CHROMA,
    FIELD_DIAMETER_RATIO,
    FIELD_CLARITY,
    FIELD_CUT,
    FIELD_SYMMETRY,
    FIELD_POLISH,
    FIELD_FLUOR_STRENGTH,
    FIELD_LW,
    FIELD_DEPTH_COMPOSED,
    FIELD_TABLE_COMPOSED,
    FIELD_CROWN_ANGLE,
    FIELD_CROWN_COMPOSED,
    FIELD_PAVILION_FIRST_ANGLE,
    FIELD_PAVILION_ANGLE,
    FIELD_PAVILION_COMPOSED,
    FIELD_GIRDLE_COMPOSED,
    FIELD_CULET_COMPOSED,
    FIELD_GIRDLE,
    FIELD_CULET,
    FIELD_SELLER,
    FIELD_CREATED_AT,
    FIELD_SHAPE,
];
export const DIAMOND_COMBO_ALL_FIELDS_MAP = [
    FIELD_LABELS_EXTENDED,
    FIELD_SKU,
    FIELD_ALT_SKU,
    ...DIAMOND_BASE_COMBO_FIELDS,
];
export const DIAMOND_COLLECTION_COMBO_FIELDS_MAP = [
    FIELD_LABELS_EXTENDED,
    FIELD_SKU,
    FIELD_ALT_SKU,
    ...DIAMOND_BASE_COMBO_FIELDS,
    FIELD_COMBINED_COMMENT_AND_SELLER_NOTE,
];
export const DIAMOND_COMBO_SOLUTIONS_FIELDS_MAP = [
    ...new Set([
        FIELD_SKU,
        FIELD_ALT_SKU,
        FIELD_PAVILION_COLOR,
        FIELD_TABLE_COLOR,
        ...DIAMOND_BASE_COMBO_FIELDS,
        FIELD_SELLER_NOTE,
    ]),
];
export const DIAMOND_COMBO_FANCY_COLOR_FIELDS_MAP = [
    ...new Set([
        FIELD_SKU,
        FIELD_ALT_SKU,
        FIELD_CHROMA,
        FIELD_HISTOGRAM,
        FIELD_COLOR_GRADE,
        ...DIAMOND_BASE_COMBO_FIELDS,
        FIELD_SELLER_NOTE,
    ]),
];
export const DIAMOND_COMBO_SOLUTION_FIELDS_MAP_ONLY_MEDIA = [FIELD_SKU, FIELD_ALT_SKU];
export const DIAMOND_COMBO_COMPARISON = [FIELD_LABELS_EXTENDED, ...DIAMOND_BASE_COMBO_FIELDS];
export const DIAMOND_COMBO_MOBILE_REFERENCE_COMPARISON = [
    FIELD_CARAT_WEIGHT,
    FIELD_CERTIFICATE,
    FIELD_SHAPE_NORMALIZED_PERFORMANCE,
    FIELD_CUT_PERFORMANCE,
    FIELD_FIRE,
    FIELD_BRILLIANCE,
    FIELD_BRILLIANCE_V2,
    FIELD_SCINTILLATION,
    FIELD_OPTICAL_SYMMETRY,
    FIELD_SPREAD,
    FIELD_FACE_BRIGHTNESS,
    FIELD_AVG_BRIGHTNESS,
    FIELD_DEAD_ZONE,
    FIELD_DIAMETER_RATIO,
    FIELD_CUT,
    FIELD_SYMMETRY,
    FIELD_POLISH,
    FIELD_LW,
    FIELD_DEPTH_COMPOSED,
    FIELD_TABLE_COMPOSED,
    FIELD_CROWN_ANGLE,
    FIELD_CROWN_COMPOSED,
    FIELD_PAVILION_FIRST_ANGLE,
    FIELD_PAVILION_ANGLE,
    FIELD_PAVILION_COMPOSED,
    FIELD_GIRDLE_COMPOSED,
    FIELD_CULET_COMPOSED,
    FIELD_GIRDLE,
    FIELD_CULET,
    FIELD_SHAPE,
];
export const DIAMOND_COMBO_REFERENCE_COMPARISON = [
    FIELD_SKU,
    FIELD_ALT_SKU,
    ...DIAMOND_COMBO_MOBILE_REFERENCE_COMPARISON,
];
export const DIAMOND_BASE_CARD_FIELDS_MAP = [
    FIELD_SHAPE_NORMALIZED_PERFORMANCE,
    FIELD_CUT_PERFORMANCE,
    FIELD_FIRE,
    FIELD_BRILLIANCE,
    FIELD_BRILLIANCE_V2,
    FIELD_SCINTILLATION,
    FIELD_OPTICAL_SYMMETRY,
    FIELD_SPREAD,
    FIELD_FLUOR,
    FIELD_FACE_BRIGHTNESS,
    FIELD_AVG_BRIGHTNESS,
    FIELD_DEAD_ZONE,
    FIELD_CARAT_WEIGHT,
    FIELD_COLOR_GRADE,
    FIELD_PAVILION_COLOR,
    FIELD_TABLE_COLOR,
    FIELD_CUSTOM_COLOR,
    FIELD_CUSTOM_GIRDLE_BEAUTY,
    FIELD_CUSTOM_CUT_PERFORMANCE,
    FIELD_CUSTOM_BRILLIANCE,
    FIELD_DIAMETER_RATIO,
    FIELD_CLARITY,
    FIELD_CUT,
    FIELD_POLISH,
    FIELD_SYMMETRY,
    FIELD_FLUOR_STRENGTH,
    FIELD_SPREAD_PC,
    FIELD_SPREAD_CT,
    FIELD_PRICE,
    FIELD_LENGTH,
    FIELD_WIDTH,
    FIELD_DEPTH_PC,
    FIELD_DEPTH_MM,
    FIELD_TABLE_PC,
    FIELD_TABLE_MM,
    FIELD_CROWN_ANGLE,
    FIELD_CROWN_PC,
    FIELD_CROWN_MM,
    FIELD_PAVILION_FIRST_ANGLE,
    FIELD_PAVILION_ANGLE,
    FIELD_PAVILION_PC,
    FIELD_PAVILION_MM,
    FIELD_GIRDLE_PC,
    FIELD_GIRDLE_MM,
    FIELD_CULET_PC,
    FIELD_CULET_MM,
    FIELD_GIRDLE,
    FIELD_CULET,
    FIELD_COLLECTION_COMMENT,
    FIELD_SELLER_NOTE,
];
export const DIAMOND_CARD_FIELDS_MAP = [FIELD_SKU, FIELD_ALT_SKU, FIELD_SHORT_VIEW, ...DIAMOND_BASE_CARD_FIELDS_MAP];
export const DIAMOND_COLORLESS_TABLE_FIELDS_MAP = [
    FIELD_LABELS,
    FIELD_SKU,
    FIELD_DRIVE_PROJECT_SUMMARY,
    FIELD_CARAT_WEIGHT,
    FIELD_COLOR_GRADE,
    FIELD_CLARITY,
    FIELD_SHAPE,
    FIELD_CREATED_AT,
    FIELD_EXPENSES,
    FIELD_NEXT_PAYMENT,
    FIELD_PRODUCT_TASKS_AVAILABLE_AT,
    FIELD_CERTIFICATE,
    FIELD_SHAPE_NORMALIZED_PERFORMANCE,
    FIELD_CUT_PERFORMANCE,
    FIELD_FIRE,
    FIELD_BRILLIANCE,
    FIELD_SCINTILLATION,
    FIELD_OPTICAL_SYMMETRY,
    FIELD_SPREAD_PC,
    FIELD_GLOBAL_ACCESS,
    FIELD_PRIVATE_ACCESS,
];
export const DIAMOND_FANCY_TABLE_FIELDS_MAP = [
    FIELD_LABELS,
    FIELD_SKU,
    FIELD_DRIVE_PROJECT_SUMMARY,
    FIELD_CARAT_WEIGHT,
    FIELD_COLOR_GRADE,
    FIELD_CREATED_AT,
    FIELD_EXPENSES,
    FIELD_NEXT_PAYMENT,
    FIELD_PRODUCT_TASKS_AVAILABLE_AT,
    FIELD_CLARITY,
    FIELD_SHAPE,
    FIELD_CERTIFICATE,
    FIELD_SPREAD_PC,
    FIELD_GLOBAL_ACCESS,
    FIELD_PRIVATE_ACCESS,
];
export const DIAMOND_COMBO_FIELDS_MAP_ONLY_MEDIA = [FIELD_LABELS_EXTENDED, FIELD_SKU, FIELD_ALT_SKU, FIELD_PRICE];
export const CARD_FIELDS_SORT = [...DIAMOND_BASE_CARD_FIELDS_MAP, FIELD_LABELS, FIELD_SHAPE, FIELD_CREATED_AT];
export const LGD_BASE_COMBO_FIELDS = [FIELD_LABELS_EXTENDED, ...DIAMOND_BASE_COMBO_FIELDS];
export const LGD_COMBO_COMPARISON = LGD_BASE_COMBO_FIELDS;
export const LGD_COLORLESS_TABLE_FIELDS_MAP = [
    FIELD_LABELS,
    FIELD_SKU,
    FIELD_DRIVE_PROJECT_SUMMARY,
    FIELD_CARAT_WEIGHT,
    FIELD_COLOR_GRADE,
    FIELD_CLARITY,
    FIELD_SHAPE,
    FIELD_CREATED_AT,
    FIELD_EXPENSES,
    FIELD_NEXT_PAYMENT,
    FIELD_PRODUCT_TASKS_AVAILABLE_AT,
    FIELD_CERTIFICATE,
    FIELD_SHAPE_NORMALIZED_PERFORMANCE,
    FIELD_CUT_PERFORMANCE,
    FIELD_FIRE,
    FIELD_BRILLIANCE,
    FIELD_SCINTILLATION,
    FIELD_OPTICAL_SYMMETRY,
    FIELD_SPREAD_PC,
    FIELD_GLOBAL_ACCESS,
    FIELD_PRIVATE_ACCESS,
];
export const LGD_FANCY_TABLE_FIELDS_MAP = [
    FIELD_LABELS,
    FIELD_SKU,
    FIELD_DRIVE_PROJECT_SUMMARY,
    FIELD_CARAT_WEIGHT,
    FIELD_COLOR_GRADE,
    FIELD_CREATED_AT,
    FIELD_EXPENSES,
    FIELD_NEXT_PAYMENT,
    FIELD_PRODUCT_TASKS_AVAILABLE_AT,
    FIELD_CLARITY,
    FIELD_SHAPE,
    FIELD_CERTIFICATE,
    FIELD_SPREAD_PC,
    FIELD_GLOBAL_ACCESS,
    FIELD_PRIVATE_ACCESS,
];
export const LGD_COMBO_FIELDS_MAP_ONLY_MEDIA = [FIELD_SKU, FIELD_ALT_SKU, FIELD_PRICE];
export const ROUGH_ALL_FIELDS = [
    FIELD_SKU,
    FIELD_ALT_SKU,
    FIELD_SHORT_VIEW,
    FIELD_SELLER,
    FIELD_PRICE,
    FIELD_CREATED_AT,
    FIELD_EXPENSES,
    FIELD_NEXT_PAYMENT,
    FIELD_PRODUCT_TASKS_AVAILABLE_AT,
    FIELD_CARAT_WEIGHT,
    FIELD_DIMENSIONS,
];
export const ROUGH_COMBO_FIELDS = ROUGH_ALL_FIELDS.filter((f) => f !== FIELD_SHORT_VIEW);
export const ROUGH_COLLECTION_COMBO_FIELDS = [...ROUGH_COMBO_FIELDS, FIELD_COMBINED_COMMENT_AND_SELLER_NOTE];
export const ROUGH_BASE_CARD_FIELDS = [...ROUGH_ALL_FIELDS, FIELD_COLLECTION_COMMENT, FIELD_SELLER_NOTE];
export const ROUGH_TABLE_FIELDS_MAP = [
    FIELD_LABELS,
    FIELD_SKU,
    FIELD_DRIVE_PROJECT_SUMMARY,
    FIELD_SELLER,
    FIELD_PRICE,
    FIELD_CREATED_AT,
    FIELD_EXPENSES,
    FIELD_NEXT_PAYMENT,
    FIELD_PRODUCT_TASKS_AVAILABLE_AT,
    FIELD_CARAT_WEIGHT,
    FIELD_DIMENSIONS,
    FIELD_GLOBAL_ACCESS,
    FIELD_PRIVATE_ACCESS,
];
export const JEWELRY_TABLE_FIELDS = [
    FIELD_LABELS,
    FIELD_SKU,
    FIELD_SELLER,
    FIELD_PRICE,
    FIELD_CREATED_AT,
    FIELD_EXPENSES,
    FIELD_NEXT_PAYMENT,
    FIELD_PRODUCT_TASKS_AVAILABLE_AT,
    FIELD_CARAT_WEIGHT,
    FIELD_DIMENSIONS,
    FIELD_GLOBAL_ACCESS,
    FIELD_PRIVATE_ACCESS,
];
export const JEWELRY_COLLECTION_COMBO_FIELDS = [...JEWELRY_TABLE_FIELDS, FIELD_COMBINED_COMMENT_AND_SELLER_NOTE];
export const JEWELRY_BASE_FIELDS = [
    FIELD_SKU,
    FIELD_ALT_SKU,
    FIELD_SHORT_VIEW,
    FIELD_SELLER,
    FIELD_PRICE,
    FIELD_CREATED_AT,
    FIELD_EXPENSES,
    FIELD_NEXT_PAYMENT,
    FIELD_PRODUCT_TASKS_AVAILABLE_AT,
    FIELD_CARAT_WEIGHT,
    FIELD_COLLECTION_COMMENT,
    FIELD_SELLER_NOTE,
];
export const JEWELRY_TABLE_FIELDS_MAP = [
    FIELD_LABELS,
    FIELD_SKU,
    FIELD_SELLER,
    FIELD_PRICE,
    FIELD_CREATED_AT,
    FIELD_EXPENSES,
    FIELD_NEXT_PAYMENT,
    FIELD_PRODUCT_TASKS_AVAILABLE_AT,
    FIELD_CARAT_WEIGHT,
    FIELD_GLOBAL_ACCESS,
    FIELD_PRIVATE_ACCESS,
];
export const CUSTOM_GRADE_FIELDS = [
    FIELD_CUSTOM_COLOR,
    FIELD_CUSTOM_GIRDLE_BEAUTY,
    FIELD_CUSTOM_CUT_PERFORMANCE,
    FIELD_CUSTOM_BRILLIANCE,
];
export const ROUGH_COMPARISON = ROUGH_COMBO_FIELDS;
export const LGD_ROUGH_COMPARISON = [FIELD_LABELS, ...ROUGH_COMPARISON];
export const COLLECTION_ONLY_FIELDS = [
    FIELD_COLLECTION_COMMENT,
    FIELD_SELLER_NOTE,
    FIELD_COMBINED_COMMENT_AND_SELLER_NOTE,
];
