import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, useContext } from 'react';
import cls from 'classnames';
import MenuContext from '@/common/components/MenuList/MenuContext/MenuContext';
import styles from './MenuItem.module.css';
function MenuItem(props) {
    const { onClick, active, disabled, className, light, children, ...rest } = props;
    const context = useContext(MenuContext);
    const handleClick = useCallback(() => {
        if (context?.onClick) {
            context.onClick();
        }
        if (onClick) {
            onClick();
        }
    }, [context, onClick]);
    return (_jsx("div", { className: cls(styles.menuItem, {
            [styles.menuItemClickable]: Boolean(onClick),
            [styles.menuItemDisabled]: disabled,
            [styles.menuItemActive]: active,
            [styles.menuItemLight]: light,
        }, className), onClick: handleClick, ...rest, children: children }));
}
export default memo(MenuItem);
