import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import Icon from '../components/Icon/Icon';
export const IMG_COMMON = 'common';
export const IMG_COMPARISON = 'comparison';
export const IMG_PRIVACY = 'privacy';
export const CODE_NOT_FOUND = 404;
export const TITLE_NOT_FOUND = 'The page you are looking for cannot be found';
export const DESCRIPTION_NOT_FOUND = 'Page may have been removed or you may have typed the web address incorrectly.';
export const CODE_ACCESS_DENIED = 403;
export const TITLE_ACCESS_DENIED = 'Access Denied';
export const DESCRIPTION_ACCESS_DENIED = '';
export const CODE_ACCESS_UNAUTHORIZED = 401;
export const TITLE_ACCESS_UNAUTHORIZED = 'Access Denied';
export const DESCRIPTION_ACCESS_UNAUTHORIZED = 'You need to authorize to use this page.';
export const CODE_PRECONDITION_FAILED = 412;
export const TITLE_PRECONDITION_FAILED = 'Precondition Failed';
export const CODE_EMPTY_BOARD = null;
export const TITLE_EMPTY_BOARD = 'Nothing to show';
export const DESCRIPTION_EMPTY_BOARD = 'This collection is empty or outdated. Probably it was removed by owner or exposed products do not exist anymore. ';
export const CODE_EMPTY_COMPARISON = null;
export const TITLE_EMPTY_COMPARISON = 'No items were added to compare';
export const DESCRIPTION_EMPTY_COMPARISON = (_jsxs(Fragment, { children: [_jsx("span", { children: "To compare any items, select the items you are interested in, and click " }), _jsx(Icon, { name: "compare", className: "withMarginBottom" }), _jsx("span", { children: " button." })] }));
export const CODE_PRIVATE_COLLECTION = null;
export const TITLE_PRIVATE_COLLECTION = 'This collection is private and can\'t be viewed';
export const DESCRIPTION_PRIVATE_COLLECTION = 'It may have been deleted or you don\'t have permission to view it right now.';
export const CODE_DEFAULT = null;
export const TITLE_DEFAULT = 'Oops';
export const DESCRIPTION_DEFAULT = 'Something went wrong.';
export const CODE_PRIVATE_DRIVE_PROJECT = null;
export const TITLE_PRIVATE_DRIVE_PROJECT = 'This multistage project is private and can\'t be viewed';
export const DESCRIPTION_PRIVATE_DRIVE_PROJECT = 'It may have been deleted or you don\'t have permission to view it right now.';
export default class AppError {
    code = CODE_DEFAULT;
    title;
    description = '';
    imgName = IMG_COMMON;
    hasCatalogLink = false;
    hasCollectionLink = false;
    hasLoginLink = false;
    constructor(code = CODE_DEFAULT, title, description = '', imgName = IMG_COMMON, hasCatalogLink = false, hasCollectionLink = false, hasLoginLink = false) {
        this.code = code;
        this.title = title;
        this.description = description;
        this.imgName = imgName;
        this.hasCatalogLink = hasCatalogLink;
        this.hasCollectionLink = hasCollectionLink;
        this.hasLoginLink = hasLoginLink;
    }
    get hasLinks() {
        return this.hasCatalogLink || this.hasCollectionLink || this.hasLoginLink;
    }
    static createErrorFromCodeAndTitle(code, title = null) {
        let error = null;
        switch (code) {
            case CODE_NOT_FOUND:
                error = AppError.create404Error();
                break;
            case CODE_ACCESS_DENIED:
                error = AppError.createAccessDeniedError();
                break;
            case CODE_ACCESS_UNAUTHORIZED:
                error = AppError.createUnauthorizedError();
                break;
            case CODE_PRECONDITION_FAILED:
                error = AppError.createPreconditionFailed(title);
                break;
            default:
                error = AppError.createDefaultError();
                break;
        }
        if (title) {
            error.title = title;
        }
        return error;
    }
    static create404Error() {
        return new AppError(CODE_NOT_FOUND, TITLE_NOT_FOUND, DESCRIPTION_NOT_FOUND, IMG_COMMON, true, true);
    }
    static createAccessDeniedError() {
        return new AppError(CODE_ACCESS_DENIED, TITLE_ACCESS_DENIED, DESCRIPTION_ACCESS_DENIED, IMG_COMMON, true, true);
    }
    static createUnauthorizedError() {
        return new AppError(CODE_ACCESS_UNAUTHORIZED, TITLE_ACCESS_UNAUTHORIZED, DESCRIPTION_ACCESS_UNAUTHORIZED, IMG_COMMON, false, false, true);
    }
    static createPreconditionFailed(description) {
        return new AppError(CODE_PRECONDITION_FAILED, TITLE_PRECONDITION_FAILED, description, IMG_COMMON);
    }
    static createPrivateCollectionError() {
        return new AppError(CODE_PRIVATE_COLLECTION, TITLE_PRIVATE_COLLECTION, DESCRIPTION_PRIVATE_COLLECTION, IMG_PRIVACY, false, true);
    }
    static createPrivateDriveProjectError() {
        return new AppError(CODE_PRIVATE_DRIVE_PROJECT, TITLE_PRIVATE_DRIVE_PROJECT, DESCRIPTION_PRIVATE_DRIVE_PROJECT, IMG_PRIVACY, false, true);
    }
    static createComparisonError() {
        return new AppError(CODE_EMPTY_COMPARISON, TITLE_EMPTY_COMPARISON, DESCRIPTION_EMPTY_COMPARISON, IMG_COMPARISON, true);
    }
    static createDefaultError() {
        return new AppError(CODE_DEFAULT, TITLE_DEFAULT, DESCRIPTION_DEFAULT, IMG_COMMON);
    }
    /** @deprecated Use "createErrorFromCodeAndTitle" instead */
    static createErrorFromBackend(errorData) {
        return AppError.createErrorFromCodeAndTitle(errorData.code, errorData.title);
    }
}
