import CutwiseAPIClient, { METHOD_GET } from '../network/CutwiseAPIClient';
export const PATH_REFERENCE = 'api/v2/diamonds/reference/colorless';
export default class ReferenceAPI {
    fetchItem(product) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(`${PATH_REFERENCE}/${product.carat}`)
            .suppressErrorNotifications();
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
}
