export default class ToFormDataSerializationStrategy {
    static serialize(data) {
        if (!data) {
            return null;
        }
        const formData = new FormData();
        Object.keys(data).forEach((dataObjectKey) => {
            formData.append(dataObjectKey, data[dataObjectKey]);
        });
        return formData;
    }
    static contentType() {
        return null;
    }
}
