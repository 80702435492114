import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react';
import { useMount } from '@cutwise/utils';
import AppError, { CODE_ACCESS_UNAUTHORIZED } from '../../state/AppError';
function ErrorChecker({ children }) {
    useMount(() => {
        const errorData = window.CUTWISE_FORCE_ERROR;
        window.CUTWISE_FORCE_ERROR = null;
        if (errorData && errorData.code !== CODE_ACCESS_UNAUTHORIZED) {
            throw AppError.createErrorFromBackend(errorData);
        }
    });
    return _jsx(Fragment, { children: children });
}
export default ErrorChecker;
