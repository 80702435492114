import { generateSearchQuery, isArray, isNumber, isString, parseSearchQuery } from '@cutwise/utils';
import { filterNumericArray } from '@/common/helpers/filterNumericArray';
export default class TasksQuerySerializer {
    static serialize(filter, order = {}, page = null) {
        const { title, taskState, taskType, ...rest } = filter;
        return generateSearchQuery({
            ...rest,
            title: title && isString(title) ? title : null,
            type: isArray(taskType) && taskType.length > 0 ? taskType : null,
            state: isArray(taskState) ? taskState : null,
            page: isNumber(page) && isFinite(page) && page > 1 ? page : null,
            order,
        });
    }
    static deserialize(queryString) {
        const query = parseSearchQuery(queryString);
        if (Object.keys(query).length === 0) {
            return {
                page: 0,
            };
        }
        const { state, type, page, ...rest } = query;
        const pageValue = page ? +page : 0;
        const result = {
            ...rest,
            page: isNumber(pageValue) && isFinite(pageValue) && pageValue > 1 ? pageValue - 1 : 0,
        };
        if (filterNumericArray(type).length > 0) {
            result.taskType = type;
        }
        if (filterNumericArray(state).length > 0) {
            result.taskState = state;
        }
        return result;
    }
}
