import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import cls from 'classnames';
import RichText from '@/common/components/RichText/RichText';
import styles from './HtmlField.module.less';
function HtmlField(props) {
    const { value, className } = props;
    return (_jsx("div", { className: cls(styles.htmlCell, className), children: _jsx(RichText, { value: value }) }));
}
export default memo(HtmlField);
