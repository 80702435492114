var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from 'mobx';
import { isSecondMonitorAvailable } from '@/common/helpers/remote';
import useStore from '@/common/hooks/useStore';
import browserHistory from '../services/history';
var PresentationAction;
(function (PresentationAction) {
    PresentationAction["Destroy"] = "destroy-presentation";
    PresentationAction["Reconnect"] = "reconnect";
    PresentationAction["LooseFocus"] = "loose-focus";
})(PresentationAction || (PresentationAction = {}));
class PresentationStore {
    focused = true;
    started = false;
    presentation = null;
    connection = null;
    /*
     * Below simple implementation of callback, now it has problem - it can be used only in one Store,
     * in future we probably will have few Stores and this implementation should be updated
     * */
    onTerminate = null;
    onMessage = null;
    constructor() {
        // When reload window or close browser - destroy presentation and close presentation window
        window.addEventListener('beforeunload', this.terminate);
        // Automatically subscribe to all messages between windows
        this.subscribe();
    }
    get isPresentationWindow() {
        /*
         * If presentation exists, it means window is opened by host window (host window is a receiver),
         * it means this window is presentation window
         * */
        return Boolean(navigator.presentation?.receiver);
    }
    getConnections = async () => {
        const list = await navigator.presentation?.receiver?.connectionList;
        return list?.connections ?? [];
    };
    start = async (url) => {
        try {
            // TODO possible duplicate, need to refactor, check remote.ts
            /*
             * If user has only one monitor connected -  we block presentation,
             * because Presentation API works only for two monitors
             *
             * We make this check here to avoid next case - user see CV presentation mode button,
             * then switch off second monitor and then press this button - in this case we shouldn't start presentation
             * */
            if (!(await isSecondMonitorAvailable())) {
                alert('You need to have at least two monitors to start presentation!');
                return null;
            }
        }
        catch (e) {
            alert('In order to start presentation, you should grant permission for "Window management" in Chrome Settings!');
            return null;
        }
        this.presentation = new PresentationRequest([url]);
        // Make this presentation the default one when using the "Cast" browser menu.
        navigator.presentation.defaultRequest = this.presentation;
        this.connection = await this.presentation.start();
        this.started = true;
        this.connection.addEventListener('terminate', (e) => {
            this.started = false;
            this.onTerminate?.(e);
        });
        this.connection.addEventListener('message', this.onReceiveMessage);
        return this.connection;
    };
    reconnect = (path) => {
        this.connection?.send(JSON.stringify({ action: PresentationAction.Reconnect, data: { path } }));
    };
    terminate = () => {
        if (!this.started) {
            return;
        }
        this.connection?.removeEventListener('message', this.onReceiveMessage);
        this.connection?.terminate();
    };
    dispose = () => {
        this.connection = null;
    };
    /*
     * "closePresentationWindow" is the way to close presentation window from inside
     *
     * Idea is to send message to host window and host window will close presentation window
     * */
    closePresentationWindow = async () => {
        const connections = await this.getConnections();
        connections.forEach((connection) => {
            connection.send(JSON.stringify({
                action: PresentationAction.Destroy,
            }));
        });
    };
    send = async (message) => {
        // Send message to stereo presentation
        if (this.connection?.state === 'connected') {
            // When open CV presentation mode, code below will send message FROM parent window TO presentation window
            this.connection?.send(JSON.stringify(message));
        }
        // When open CV presentation mode, code below will send message FROM presentation window TO parent window
        const connections = await this.getConnections();
        connections.forEach((connection) => {
            if (connection.state === 'connected') {
                connection.send(JSON.stringify(message));
            }
        });
    };
    looseFocus = () => {
        if (this.connection && this.connection?.state !== 'terminated' && this.connection?.state !== 'closed') {
            this.connection.send(JSON.stringify({ action: PresentationAction.LooseFocus }));
        }
    };
    subscribe = async () => {
        this.connection?.addEventListener('message', this.onReceiveMessage);
        const connections = await this.getConnections();
        connections.forEach((connection) => {
            connection.addEventListener('message', this.onReceiveMessage);
        });
    };
    onReceiveMessage = (e) => {
        try {
            const data = JSON.parse(e.data);
            switch (data.action) {
                case PresentationAction.Destroy:
                    this.connection?.terminate();
                    return;
                case PresentationAction.Reconnect:
                    if (data.data.path) {
                        browserHistory.replace(data.data.path);
                        this.focused = true;
                    }
                    return;
                case PresentationAction.LooseFocus:
                    this.focused = false;
                    return;
                default:
                    break;
            }
        }
        catch (e) {
            return;
        }
        this.onMessage?.(e);
    };
}
__decorate([
    observable
], PresentationStore.prototype, "focused", void 0);
__decorate([
    observable
], PresentationStore.prototype, "started", void 0);
export const presentationStore = new PresentationStore();
export const usePresentationStore = () => useStore(PresentationStore);
export default PresentationStore;
