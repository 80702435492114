import { isValid } from 'date-fns';
export function datesDiff(finish, start) {
    const finishDate = new Date(finish);
    const startDate = new Date(start);
    if (!isValid(finishDate) && !isValid(startDate)) {
        throw new Error('Both arguments must be valid dates');
    }
    const diff = startDate.valueOf() - finishDate.valueOf();
    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const months = Math.floor(days / 30); // approximate value
    const years = Math.floor(days / 365); // approximate value
    const measurements = {
        minutes,
        hours,
        days,
        months,
        years,
    };
    let label = '';
    if (minutes < 0) {
        label = '';
    }
    else if (minutes < 1) {
        label = '< 1 min';
    }
    else if (hours < 1) {
        label = `${minutes} min`;
    }
    else if (days < 1) {
        const remainingMinutes = minutes % 60;
        label = `${hours} hour${hours > 1 ? 's' : ''}${remainingMinutes > 0 ? ` ${remainingMinutes} min${remainingMinutes > 1 ? 's' : ''}` : ''}`;
    }
    else if (days < 30) {
        const remainingHours = hours % 24;
        label = `${days} day${days > 1 ? 's' : ''}${remainingHours > 0 ? ` ${remainingHours} hour${remainingHours > 1 ? 's' : ''}` : ''}`;
    }
    else if (months < 12) {
        const remainingDays = days % 30;
        label = `${months} month${months > 1 ? 's' : ''}${remainingDays > 0 ? ` ${remainingDays} day${remainingDays > 1 ? 's' : ''}` : ''}`;
    }
    else {
        const remainingMonths = Math.floor((days % 365) / 30);
        label = `${years} year${years > 1 ? 's' : ''}${remainingMonths > 0 ? ` ${remainingMonths} month${remainingMonths > 1 ? 's' : ''}` : ''}`;
    }
    return [label, measurements];
}
