export var TaskState;
(function (TaskState) {
    TaskState[TaskState["Pending"] = -1] = "Pending";
    TaskState[TaskState["Completed"] = 4] = "Completed";
    TaskState[TaskState["Failed"] = 5] = "Failed";
    TaskState[TaskState["Cancelled"] = 6] = "Cancelled";
})(TaskState || (TaskState = {}));
export var TaskStateValue;
(function (TaskStateValue) {
    TaskStateValue["Pending"] = "Pending";
    TaskStateValue["Completed"] = "Completed";
    TaskStateValue["Failed"] = "Failed";
    TaskStateValue["Cancelled"] = "Cancelled";
})(TaskStateValue || (TaskStateValue = {}));
export const TASK_STATE_MAP = {
    [TaskState.Pending]: TaskStateValue.Pending,
    [TaskState.Completed]: TaskStateValue.Completed,
    [TaskState.Failed]: TaskStateValue.Failed,
    [TaskState.Cancelled]: TaskStateValue.Cancelled,
};
