import isEqual from 'lodash/isEqual';
import { isString } from '@cutwise/utils';
import { ASC, DESC } from '@/common/constants/sort';
export default class Sort {
    sortFields = [];
    constructor() {
        this.sortFields = [];
    }
    static get DIRECTION_ASC() {
        return ASC;
    }
    static get DIRECTION_DESC() {
        return DESC;
    }
    static get SORT_MEDIA_RELEVANCE() {
        return 'mediaRelevance';
    }
    static get SORT_MANUAL() {
        return 'manual';
    }
    static get DEFAULT_SORT() {
        return Sort.SORT_MEDIA_RELEVANCE;
    }
    // TODO remove, transform duplicate
    static decode(transformedSort) {
        if (!transformedSort) {
            return null;
        }
        const decodedField = Object.keys(transformedSort)[0];
        const decodedDirection = transformedSort[decodedField];
        return { field: decodedField, direction: decodedDirection };
    }
    static fromJSON(orderByFromServer) {
        const sort = new Sort();
        Object.keys(orderByFromServer).forEach((sortField) => sort.addMultiSort(sortField, orderByFromServer[sortField]));
        return sort;
    }
    static makeManual() {
        return new Sort().setSingleSort(Sort.SORT_MANUAL, Sort.DIRECTION_DESC);
    }
    setSingleSort(field, direction) {
        if (isString(field) && field && [ASC, DESC].includes(direction)) {
            this.sortFields = [{ field, direction }];
        }
        return this;
    }
    getSortDirection(fieldName) {
        const sortField = this.getFieldByName(fieldName);
        return sortField ? sortField.direction : Sort.DIRECTION_DESC;
    }
    getFieldByName(fieldName) {
        return this.sortFields.find((sortItem) => sortItem.field === fieldName);
    }
    addMultiSort(field, direction) {
        this.sortFields.push({ field, direction });
        return this;
    }
    getFirstField() {
        return this.sortFields && this.sortFields.length > 0 ? this.sortFields[0].field : null;
    }
    hasField(fieldName) {
        return !!this.getFieldByName(fieldName);
    }
    transform() {
        const transformedSort = {};
        this.sortFields.forEach((sort) => {
            transformedSort[sort.field] = sort.direction;
        });
        return transformedSort;
    }
    isEqual(object) {
        if (!object) {
            return false;
        }
        const transformedThis = this.transform();
        const transformedObject = object.transform();
        return isEqual(transformedThis, transformedObject);
    }
}
