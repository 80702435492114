import { IMAGE, VIDEO_MONO, VIDEO_STEREO } from '../constants/mediaSourceTypes';
export var SetupPresetType;
(function (SetupPresetType) {
    SetupPresetType[SetupPresetType["View360Girdle"] = 1] = "View360Girdle";
    SetupPresetType[SetupPresetType["Video360Pavilion"] = 2] = "Video360Pavilion";
    SetupPresetType[SetupPresetType["ViBOXVerticalSwinging"] = 3] = "ViBOXVerticalSwinging";
    SetupPresetType[SetupPresetType["ViBOXSwinging"] = 4] = "ViBOXSwinging";
    SetupPresetType[SetupPresetType["IOffice"] = 7] = "IOffice";
    SetupPresetType[SetupPresetType["IAsetBlack"] = 10] = "IAsetBlack";
    SetupPresetType[SetupPresetType["IAsetWhite"] = 11] = "IAsetWhite";
    SetupPresetType[SetupPresetType["IArrows"] = 13] = "IArrows";
    SetupPresetType[SetupPresetType["IHeart"] = 14] = "IHeart";
    SetupPresetType[SetupPresetType["IMunsell"] = 15] = "IMunsell";
    SetupPresetType[SetupPresetType["IMunsellCloseup"] = 16] = "IMunsellCloseup";
    SetupPresetType[SetupPresetType["DarkfieldCrown"] = 23] = "DarkfieldCrown";
    SetupPresetType[SetupPresetType["ViBOXGyroFire"] = 28] = "ViBOXGyroFire";
    SetupPresetType[SetupPresetType["IFaceUpFluor"] = 31] = "IFaceUpFluor";
    SetupPresetType[SetupPresetType["GyroDarkfield"] = 32] = "GyroDarkfield";
    SetupPresetType[SetupPresetType["GyroFire2D"] = 33] = "GyroFire2D";
    SetupPresetType[SetupPresetType["LPFaceUpFluor"] = 38] = "LPFaceUpFluor";
    SetupPresetType[SetupPresetType["Scintillation"] = 40] = "Scintillation";
    SetupPresetType[SetupPresetType["LPOffice"] = 41] = "LPOffice";
    SetupPresetType[SetupPresetType["ITColorOutdoor"] = 42] = "ITColorOutdoor";
    SetupPresetType[SetupPresetType["Fire"] = 43] = "Fire";
    SetupPresetType[SetupPresetType["LPFire"] = 44] = "LPFire";
    SetupPresetType[SetupPresetType["Office"] = 45] = "Office";
    SetupPresetType[SetupPresetType["LPAsetBlack"] = 47] = "LPAsetBlack";
    SetupPresetType[SetupPresetType["IDarkfield"] = 50] = "IDarkfield";
    SetupPresetType[SetupPresetType["Darkfield"] = 51] = "Darkfield";
    SetupPresetType[SetupPresetType["IPavColorLab"] = 52] = "IPavColorLab";
    SetupPresetType[SetupPresetType["LPPavColorLab"] = 53] = "LPPavColorLab";
    SetupPresetType[SetupPresetType["IFire"] = 54] = "IFire";
    SetupPresetType[SetupPresetType["ISideFluor"] = 55] = "ISideFluor";
    SetupPresetType[SetupPresetType["ITColorLab"] = 56] = "ITColorLab";
    SetupPresetType[SetupPresetType["ITColorUVFree"] = 57] = "ITColorUVFree";
    SetupPresetType[SetupPresetType["IAsetBlackTabledown"] = 58] = "IAsetBlackTabledown";
    SetupPresetType[SetupPresetType["IBrightfield"] = 59] = "IBrightfield";
    SetupPresetType[SetupPresetType["Brightfield"] = 60] = "Brightfield";
    SetupPresetType[SetupPresetType["IWhitedom"] = 61] = "IWhitedom";
    SetupPresetType[SetupPresetType["IFaceUpFluorDark"] = 62] = "IFaceUpFluorDark";
    SetupPresetType[SetupPresetType["ISideFluorDark"] = 63] = "ISideFluorDark";
    SetupPresetType[SetupPresetType["LPTColorLab"] = 64] = "LPTColorLab";
    SetupPresetType[SetupPresetType["LPSideFluor"] = 68] = "LPSideFluor";
    SetupPresetType[SetupPresetType["Vision360Girdle"] = 81] = "Vision360Girdle";
    SetupPresetType[SetupPresetType["Vision360Pavilion"] = 82] = "Vision360Pavilion";
    SetupPresetType[SetupPresetType["Rough360Girdle"] = 88] = "Rough360Girdle";
    SetupPresetType[SetupPresetType["Rough360Pavilion"] = 89] = "Rough360Pavilion";
    SetupPresetType[SetupPresetType["ViBOX2Vertical"] = 98] = "ViBOX2Vertical";
    SetupPresetType[SetupPresetType["ViBOX2Pavilion"] = 99] = "ViBOX2Pavilion";
})(SetupPresetType || (SetupPresetType = {}));
export const I_FACEUP_FLUOR = SetupPresetType.IFaceUpFluor;
export const GYRO_DARKFIELD = SetupPresetType.GyroDarkfield;
export const GYRO_FIRE_2D = SetupPresetType.GyroFire2D;
export const VIDEO_360_GIRDLE = SetupPresetType.View360Girdle;
export const VIDEO_360_PAVILION = SetupPresetType.Video360Pavilion;
export const DARKFIELD_CROWN = SetupPresetType.DarkfieldCrown;
export const VIBOX_GYRO_FIRE = SetupPresetType.ViBOXGyroFire;
export const VIBOX_VERTICAL_SWINGING = SetupPresetType.ViBOXVerticalSwinging;
export const VIBOX_SWINGING = SetupPresetType.ViBOXSwinging;
export const I_OFFICE = SetupPresetType.IOffice;
export const I_ASET_BLACK = SetupPresetType.IAsetBlack;
export const I_ASET_WHITE = SetupPresetType.IAsetWhite;
export const I_ARROWS = SetupPresetType.IArrows;
export const I_HEARTS = SetupPresetType.IHeart;
export const I_MUNSELL_CLOSEUP = SetupPresetType.IMunsellCloseup;
export const I_MUNSELL = SetupPresetType.IMunsell;
export const I_DARKFIELD = SetupPresetType.IDarkfield;
export const DARKFIELD = SetupPresetType.Darkfield;
export const I_SIDE_FLUOR = SetupPresetType.ISideFluor;
export const I_ASET_BLACK_TABLEDOWN = SetupPresetType.IAsetBlackTabledown;
export const I_WHITEDOME = SetupPresetType.IWhitedom;
export const I_FACEUP_FLUOR_DARK = SetupPresetType.IFaceUpFluorDark;
export const I_SIDE_FLUOR_DARK = SetupPresetType.ISideFluorDark;
export const GYRO_SETUP_PRESETS = [SetupPresetType.GyroDarkfield, SetupPresetType.GyroFire2D];
export const LP_FACEUP_FLUOR = SetupPresetType.LPFaceUpFluor;
export const LP_TCOLOR_LAB = SetupPresetType.LPTColorLab;
export const SCINTILLATION = SetupPresetType.Scintillation;
export const LP_OFFICE = SetupPresetType.LPOffice;
export const I_TCOLOR_OUTDOOR = SetupPresetType.ITColorOutdoor;
export const FIRE = SetupPresetType.Fire;
export const LP_FIRE = SetupPresetType.LPFire;
export const OFFICE = SetupPresetType.Office;
export const LP_ASET_BLACK = SetupPresetType.LPAsetBlack;
export const I_PAVCOLOR_LAB = SetupPresetType.IPavColorLab;
export const LP_PAVCOLOR_LAB = SetupPresetType.LPPavColorLab;
export const I_FIRE = SetupPresetType.IFire;
export const I_TCOLOR_LAB = SetupPresetType.ITColorLab;
export const I_TCOLOR_UVFREE = SetupPresetType.ITColorUVFree;
export const I_BRIGHTFIELD = SetupPresetType.IBrightfield;
export const BRIGHTFIELD = SetupPresetType.Brightfield;
export const LP_SIDE_FLUOR = SetupPresetType.LPSideFluor;
export const VISION_360_GIRDLE = SetupPresetType.Vision360Girdle;
export const VISION_360_PAVILION = SetupPresetType.Vision360Pavilion;
export const ROUGH_360_GIRDLE = SetupPresetType.Rough360Girdle;
export const ROUGH_360_PAVILION = SetupPresetType.Rough360Pavilion;
export const VIBOX2_VERTICAL = SetupPresetType.ViBOX2Vertical;
export const VIBOX2_PAVILION = SetupPresetType.ViBOX2Pavilion;
const EXCLUDED_SINGLE_SCALE_SETUP_PRESETS = [
    SetupPresetType.IMunsell,
    SetupPresetType.IMunsellCloseup,
    SetupPresetType.Vision360Girdle,
    SetupPresetType.Vision360Pavilion,
    SetupPresetType.Rough360Girdle,
    SetupPresetType.Rough360Pavilion,
];
export class SetupPresetModel {
    id;
    direction;
    title;
    mediaSourceType;
    bg;
}
export default class SetupPreset extends SetupPresetModel {
    isStandard;
    constructor(modelData, isStandard) {
        super();
        this.id = modelData.id;
        this.direction = modelData.direction;
        this.title = modelData.title;
        this.mediaSourceType = modelData.mediaSourceType;
        this.bg = modelData.bg;
        this.isStandard = isStandard;
    }
    get isImage() {
        return this.mediaSourceType === IMAGE;
    }
    get isVideo() {
        return this.mediaSourceType === VIDEO_MONO || this.mediaSourceType === VIDEO_STEREO;
    }
    get isFourView() {
        return this.id === VIDEO_360_GIRDLE || this.id === VIDEO_360_PAVILION;
    }
    get isGyro() {
        return GYRO_SETUP_PRESETS.includes(this.id);
    }
    get isPossibleSingleScale() {
        return !EXCLUDED_SINGLE_SCALE_SETUP_PRESETS.includes(this.id);
    }
}
